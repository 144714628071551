<template>
    <section id="countdown-section" class="uk-background-cover uk-section uk-section-large uk-text-center uk-light" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
      <div class="uk-container">
        <p class="uk-text-center">CÙNG ĐẾM NGƯỢC THỜI GIAN</p>
        <h2 class="uk-heading-small uk-text-center script-font">SAVE THE DATE</h2>
        <svg class="heartbeat" width="64" height="64" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="red" d="M16.5 13.287c-2.475-2.716-5.5-.712-5.5 2.112c0 2.56 1.814 4.035 3.358 5.292l.044.036l.427.35c.571.475 1.121.923 1.671.923s1.1-.448 1.671-.923C19.789 19.73 22 18.224 22 15.399c0-.927-.326-1.767-.853-2.38c-1.075-1.251-2.985-1.556-4.647.268Z" />
          <path fill="#f1ebe4" d="M8.106 18.247C5.298 16.083 2 13.542 2 9.137C2 4.274 7.5.825 12 5.501C16.5.825 22 4.274 22 9.137c0 .834-.118 1.6-.329 2.31a4.203 4.203 0 0 0-2.619-.947c-.89-.005-1.758.274-2.553.81c-1.39-.933-2.956-1.058-4.33-.395c-1.635.79-2.669 2.556-2.669 4.484c0 2.306 1.149 3.923 2.342 5.095c-.948-.076-1.897-.808-2.88-1.583c-.277-.219-.564-.44-.856-.664Z" />
        </svg>
        <div class="uk-grid-small uk-child-width-1-4 uk-flex-center uk-margin-large-top" uk-grid uk-countdown="date: 2024-02-04T00:00:00+00:00">
          <div>
            <div class="uk-countdown-number uk-countdown-days uk-padding-small uk-flex uk-flex-center uk-flex-middle blur-bg uk-border-rounded text-white"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center">Ngày</div>
          </div>
          <div>
            <div class="uk-countdown-number uk-countdown-hours uk-padding-small uk-flex uk-flex-center uk-flex-middle blur-bg uk-border-rounded text-white"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center">Giờ</div>
          </div>
          <div>
            <div class="uk-countdown-number uk-countdown-minutes uk-padding-small uk-flex uk-flex-center uk-flex-middle blur-bg uk-border-rounded text-white"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center">Phút</div>
          </div>
          <div>
            <div class="uk-countdown-number uk-countdown-seconds uk-padding-small uk-flex uk-flex-center uk-flex-middle blur-bg uk-border-rounded text-white"></div>
            <div class="uk-countdown-label uk-margin-small uk-text-center">Giây</div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'CountdownComponent',
    data() {
      return {
        backgroundImage: require('@/assets/images/79F59410-7275-47A9-906F-9BA0E1C03B47.jpeg')
      };
    }
  }
  </script>
  
  <style scoped>
  #countdown-section {
    padding: 80px 0;
    background-size: cover;
    background-position: center;
  }
  
  .uk-heading-small {
    font-family: 'Great Vibes', cursive;
    font-size: 3rem;
    color: #fff;
  }
  
  .heartbeat {
    margin: 20px 0;
  }
  
  .uk-countdown-number {
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
  
  .uk-countdown-label {
    font-size: 1rem;
    color: #fff;
  }
  
  .blur-bg {
    backdrop-filter: blur(10px);
  }
  </style>