<template>
    <section id="name" class="section-name">
      <div class="container name uk-width-1-1">
        <div class="group-name">
          <h2 class="uk-heading-small script-font uk-text-center uk-padding-small" data-aos="fade-up" data-aos-duration="3000">
            Minh Tuệ
          </h2>
          <span class="a">&</span>
          <h2 class="uk-heading-small script-font uk-text-center uk-padding-small" data-aos="fade-down" data-aos-duration="3000">
            Bảo Thi
          </h2>
        </div>
        <div class="time" data-aos="fade-up" data-aos-duration="3000">
          <span class="invitation_day">09</span>
          .
          <span class="invitation_month">01</span>
          .
          <span class="invitation_year">2025</span>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'NameComponent'
  }
  </script>
  
  <style scoped>
  .section-name {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .group-name {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uk-heading-small {
    font-family: 'Great Vibes', cursive;
    font-size: 3rem;
    color: #333;
  }
  
  .a {
    font-size: 2rem;
    margin: 10px 0;
  }
  
  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #666;
  }
  
  .time span {
    margin: 0 5px;
  }
  </style>