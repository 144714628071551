<template>
    <section id="time-line" class="time-line-section">
      <div class="time-line container">
        <span class="line1"></span>
        <span class="line2"></span>
        <h2 class="timeline-title">Love Story</h2>
        <div class="timeline-list">
          <div class="timeline-item" v-for="(event, index) in events" :key="index" data-aos="fade-up" data-aos-duration="1000">
            <div class="timeline-media">
              <img :src="event.image" :alt="event.title">
            </div>
            <div class="timeline-body">
              <span class="date">{{ event.date }}</span>
              <span class="title">{{ event.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'TimelineComponent',
    data() {
      return {
        events: [
          {
            date: '2013',
            title: 'Lần đầu gặp gỡ',
            image: '@/assets/images/39C1C000-709D-4963-8E3D-ED0C9AEF3F71.jpeg'
          },
          {
            date: '2023',
            title: 'Hẹn hò',
            image: '@/assets/images/A75EC5AB-B03D-4D62-8766-6CBE94EDD5FD.jpeg'
          },
          {
            date: '24-08-2023',
            title: 'Đính hôn',
            image: '@/assets/images/2A0284C2-4B3B-4540-9C7B-B1366C01F2FB.jpeg'
          },
          {
            date: '04-02-2024',
            title: 'Thành hôn',
            image: '@/assets/images/16E9E153-3933-4589-9A60-5282FAEB9DB8.jpeg'
          }
        ]
      };
    }
  }
  </script>
  
  <style scoped>
  .time-line-section {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .time-line {
    position: relative;
    padding: 0 20px;
  }
  
  .line1, .line2 {
    position: absolute;
    width: 2px;
    background-color: #ddd;
  }
  
  .line1 {
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .line2 {
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .timeline-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
  }
  
  .timeline-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 400px;
    width: 100%;
  }
  
  .timeline-media img {
    width: 100%;
    height: auto;
  }
  
  .timeline-body {
    padding: 20px;
    text-align: center;
  }
  
  .date {
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 1.5rem;
    color: #333;
  }
  </style>