<template>
    <section id="album" class="uk-section" data-aos="fade-up" data-aos-duration="3000">
      <div class="section-heading">
        <p class="subtitle"></p>
        <h2 class="uk-heading-small uk-text-center script-font">ALBUM ẢNH</h2>
      </div>
      <div class="album container">
        <div class="album-item" v-for="(image, index) in images" :key="index">
          <a :data-fancybox="'gallery'" :href="image">
            <img :src="image" :alt="'Album image ' + (index + 1)">
          </a>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AlbumComponent',
    data() {
      return {
        images: [
          "@/assets/images/0D651757-8A73-4D91-80F8-6977659C564C.jpeg",
          "@/assets/images/20BA7A2D-4A53-4CB6-9AF3-B1E602E13D5C.jpeg",
          "@/assets/images/2D4F60FE-2B21-4737-8662-98BE0AA46CD7.jpeg",
          "@/assets/images/79F59410-7275-47A9-906F-9BA0E1C03B47.jpeg",
          "@/assets/images/C82DCDFB-1E2E-4EE2-B997-57A964682082.jpeg",
          "@/assets/images/39C1C000-709D-4963-8E3D-ED0C9AEF3F71.jpeg",
          "@/assets/images/A75EC5AB-B03D-4D62-8766-6CBE94EDD5FD.jpeg",
          "@/assets/images/926C5FC6-16AB-4C56-9CBE-F9EF8B613250.jpeg",
          "@/assets/images/2A0284C2-4B3B-4540-9C7B-B1366C01F2FB.jpeg",
          "@/assets/images/16E9E153-3933-4589-9A60-5282FAEB9DB8.jpeg",
          "@/assets/images/132D767D-5622-4371-B00A-E3E3A5D8F6B0.jpeg",
          "@/assets/images/D2AEBDFB-38E9-44DD-A9EC-E3657031EB30.jpeg"
        ]
      };
    }
  }
  </script>
  
  <style scoped>
  .uk-section {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .section-heading {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .uk-heading-small {
    font-size: 2.5rem;
    color: #333;
  }
  
  .album {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .album-item {
    width: calc(33.333% - 20px);
    margin-bottom: 20px;
  }
  
  .album-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .album-item img:hover {
    transform: scale(1.05);
  }
  </style>