<template>
    <section id="banner" class="section-banner">
      <div class="banner-container">
        <img src="@/assets/images/bg-banner.png" alt="" class="banner-bg">
        <div class="banner-slide swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(image, index) in images" :key="index">
              <img :src="image" />
            </div>
          </div>
        </div>
        <div class="banner-name-left">
          <h2 class="groom_name" data-aos="fade-right" data-aos-duration="3000">Minh Tuệ</h2>
        </div>
        <div class="banner-name-right">
          <h2 class="bride_name" data-aos="fade-left" data-aos-duration="3000">Bảo Thi</h2>
        </div>
      </div>
      <div class="date-group" data-aos="fade-up" data-aos-duration="2000">
        <div class="banner-date">09</div>
        <div class="banner-date">01</div>
        <div class="banner-date">2025</div>
      </div>
    </section>
  </template>
  
  <script>
  // import Swiper from 'swiper/bundle';
  import Swiper from 'swiper';
  import '@/assets/css/swiper-bundle.min.css';
  
  export default {
    name: 'BannerComponent',
    data() {
      return {
        images: [
          "@/assets/images/img4.jpg",
          "@/assets/images/img6.jpg",
          "@/assets/images/img4.jpg",
          "@/assets/images/img3.jpg"
        ]
      };
    },
    mounted() {
      new Swiper('.banner-slide', {
        effect: 'fade',
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
      });
    }
  }
  </script>
  
  <style scoped>
  .section-banner {
    position: relative;
    overflow: hidden;
  }
  
  .banner-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .banner-slide {
    width: 100%;
    height: 100%;
  }
  
  .banner-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .banner-name-left, .banner-name-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .banner-name-left {
    left: 10%;
  }
  
  .banner-name-right {
    right: 10%;
  }
  
  .date-group {
    position: absolute;
    bottom: 10%;
    display: flex;
    gap: 10px;
  }
  
  .banner-date {
    font-size: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
  </style>