<template>
    <section id="about" class="section-about">
      <div class="section-heading">
        <h2 class="section-title">CHÚ RỂ VÀ CÔ DÂU</h2>
      </div>
      <div class="about-container container">
        <div class="about-card" style="order:1">
          <div class="about-media" data-aos="fade-left" data-aos-duration="3000">
            <img src="@/assets/images/C82DCDFB-1E2E-4EE2-B997-57A964682082.jpeg" alt="" class="bride_img"/>
          </div>
          <div class="about-data" data-aos="fade-right" data-aos-duration="3000">
            <p class="title">Cô dâu</p>
            <h3 class="bride_name">Bảo Thi</h3>
            <p class="bride_birthday">03/05/1989</p>
            <p class="bride_des">Cô dâu thuộc tuýp người hướng nội. Sở thích nấu nướng và đi du lịch cùng gia đình.</p>   
            <hr/>
          </div>
        </div>
        <div class="about-card" style="order:1">
          <div class="about-media" data-aos="fade-right" data-aos-duration="3000">
            <img src="@/assets/images/img6.jpg" alt="" class="groom_img">
          </div>
          <div class="about-data" data-aos="fade-left" data-aos-duration="3000">
            <p class="title">Chú rể</p>
            <h3 class="groom_name">Minh Tuệ</h3>
            <p class="groom_birthday">11/03/1990</p>
            <p class="groom_des">Chú rể là người cởi mở, thân thiện, giao tiếp tốt và thuộc tuýp người hướng ngoại.</p>
            <hr/>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutComponent'
  }
  </script>
  
  <style scoped>
  .section-about {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .section-heading {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #333;
  }
  
  .about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .about-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 400px;
    width: 100%;
  }
  
  .about-media img {
    width: 100%;
    height: auto;
  }
  
  .about-data {
    padding: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 1.5rem;
    color: #666;
  }
  
  .bride_name, .groom_name {
    font-size: 2rem;
    color: #333;
    margin: 10px 0;
  }
  
  .bride_birthday, .groom_birthday {
    font-size: 1rem;
    color: #999;
  }
  
  .bride_des, .groom_des {
    font-size: 1rem;
    color: #666;
    margin: 10px 0;
  }
  
  hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
  </style>