<template>
  <section id="invitation" class="section-invitation">
    <div class="container">
      <div class="section-heading">
        <h2 class="section-title">Invitation</h2>
      </div>
      <div class="uk-section">
        <div class="uk-grid uk-child-width-1-2@m" uk-grid>
          <div class="invitation-card" v-for="(invitation, index) in invitations" :key="index">
            <div class="invitation-media">
              <img :src="invitation.image" :alt="invitation.title">
            </div>
            <div class="invitation-body">
              <h3 class="invitation-title">{{ invitation.title }}</h3>
              <div class="uk-light">
                <a :href="'tel:' + invitation.phone" class="uk-icon-button uk-margin-small-right" target="_blank" uk-icon="receiver"></a>
                <a :href="invitation.map" class="uk-icon-button uk-margin-small-right" target="_blank" uk-icon="location"></a>
                <a data-fancybox="" data-src="#gift-container" href="" class="uk-icon-button"><i class="ri-gift-line"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InvitationComponent',
  data() {
    return {
      invitations: [
        {
          title: 'TƯ GIA NHÀ GÁI',
          image: require('@/assets/images/926C5FC6-16AB-4C56-9CBE-F9EF8B613250.jpeg'),
          phone: '981742365',
          map: 'https://maps.app.goo.gl/fPfvAhoTgquMLP8m9'
        },
        {
          title: 'TƯ GIA NHÀ TRAI',
          image: require('@/assets/images/D2AEBDFB-38E9-44DD-A9EC-E3657031EB30.jpeg'),
          phone: '981742365',
          map: 'https://maps.app.goo.gl/fPfvAhoTgquMLP8m9'
        }
      ]
    };
  }
}
</script>

<style scoped>
.section-invitation {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.section-heading {
  text-align: center;
  margin-bottom: 30px;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
}

.uk-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invitation-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
}

.invitation-media img {
  width: 100%;
  height: auto;
}

.invitation-body {
  padding: 20px;
  text-align: center;
}

.invitation-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.uk-light .uk-icon-button {
  margin: 0 5px;
  color: #fff;
  background-color: #333;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.uk-light .uk-icon-button:hover {
  background-color: #555;
}
</style>