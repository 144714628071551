import { createApp } from 'vue';

import App from './App.vue';
import router from './router';


// Import CSS files from node_modules
import 'uikit/dist/css/uikit.min.css';
import 'aos/dist/aos.css';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '@/assets/css/swiper-bundle.min.css';

// Import JavaScript files from node_modules
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import AOS from 'aos';
// import { Fancybox } from '@fancyapps/ui';
// import Swiper from 'swiper/bundle';

// Initialize UIkit icons
UIkit.use(Icons);

// Initialize AOS
AOS.init();

// Vue.config.productionTip = false;

// Create the Vue app
const app = createApp(App);

// Use the router
app.use(router);

// Mount the app
app.mount('#app');