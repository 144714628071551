<template>
    <section id="video" class="section-video">
      <div class="container">
        <p class="subtitle">Our Special Moments</p>
        <h2 class="section-title">Wedding Videos</h2>
      </div>
      <div class="video-container">
        <div class="video-list">
          <div class="video-item" v-for="(video, index) in videos" :key="index">
            <iframe :src="video.url" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'VideoComponent',
    data() {
      return {
        videos: [
          { url: 'https://www.youtube.com/embed/VIDEO_ID_1' },
          { url: 'https://www.youtube.com/embed/VIDEO_ID_2' },
          { url: 'https://www.youtube.com/embed/VIDEO_ID_3' }
        ]
      };
    }
  }
  </script>
  
  <style scoped>
  .section-video {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .container {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #666;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #333;
  }
  
  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .video-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  
  .video-item iframe {
    aspect-ratio: 16/9;
    width: 100%;
    max-width: 768px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  </style>