<template>
    <section id="message" class="section-message uk-background-cover uk-section" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
      <div class="uk-position-cover uk-overlay uk-overlay-primary" style="opacity:.5"></div>
      <div class="message-container container uk-position-relative uk-position-z-index">
        <h2 class="section-title uk-light" style="color:white">Gửi lời chúc đến cặp đôi</h2>
        <div class="list_message">
          <div class="message-item" v-for="(message, index) in messages" :key="index" data-aos="fade-up" data-aos-duration="2000">
            <i class='bx bxs-quote-alt-left'></i>
            <h3 class="user_message">{{ message.author }}</h3>
            <p class="message_body">{{ message.content }}</p>
          </div>
        </div>
        <div class="uk-padding-small uk-border-rounded blur-bg" style="border:1px solid white">
          <h3 class="form-title" style="color:white">Gửi lời chúc</h3>
          <form @submit.prevent="submitMessage">
            <div class="input-group uk-margin-top" style="color:white">
              <label for="m_name">Tên của bạn</label>
              <input v-model="newMessage.author" id="m_name" type="text" class="input blur-bg" required />
            </div>
            <div class="input-group uk-margin-top">
              <label for="m_text" style="color:white">Lời nhắn gửi</label>
              <textarea v-model="newMessage.content" id="m_text" class="input-text text-white blur-bg" cols="30" rows="5" required></textarea>
            </div>
            <button type="submit" class="btn btn-primary send_message uk-margin-top" style="color:white">Gửi lời chúc</button>
          </form>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'MessageComponent',
    data() {
      return {
        // backgroundImage: require('@/assets/images/your-background-image.jpg'),
        messages: [],
        newMessage: {
          author: '',
          content: ''
        }
      };
    },
    methods: {
      fetchMessages() {
        fetch('/wp-json/wp/v2/comments?post=1304&per_page=100')
          .then(response => response.json())
          .then(data => {
            this.messages = data.map(comment => ({
              author: comment.author_name,
              content: comment.content.rendered
            }));
          });
      },
      submitMessage() {
        const data = JSON.stringify({
          post: 1304,
          author_name: this.newMessage.author,
          content: this.newMessage.content
        });
        fetch('/wp-json/wp/v2/comments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: data
        })
          .then(response => response.json())
          .then(comment => {
            this.messages.unshift({
              author: comment.author_name,
              content: comment.content.rendered
            });
            this.newMessage.author = '';
            this.newMessage.content = '';
          })
          .catch(error => console.error('Error:', error));
      }
    },
    mounted() {
      this.fetchMessages();
    }
  }
  </script>
  
  <style scoped>
  .section-message {
    padding: 50px 0;
    background-size: cover;
    background-position: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .message-container {
    position: relative;
    z-index: 1;
  }
  
  .list_message {
    margin-bottom: 30px;
  }
  
  .message-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .user_message {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .message_body {
    font-size: 1rem;
    color: #666;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input, .input-text {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .btn {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #555;
  }
  
  .blur-bg {
    backdrop-filter: blur(10px);
  }
  </style>