<template>
    <div>
      <PreloaderComponent />
      <BannerComponent />
      <NameComponent />
      <AboutComponent />
      <TimelineComponent />
      <AlbumComponent />
      <VideoComponent />
      <InvitationComponent />
      <CountdownComponent />
      <MessageComponent />
      <FooterComponent />
    </div>
  </template>
  
  <script>
  import PreloaderComponent from '@/components/preloader/PreloaderComponent.vue';
  import BannerComponent from '@/components/banner/BannerComponent.vue';
  import NameComponent from '@/components/name/NameComponent.vue';
  import AboutComponent from '@/components/about/AboutComponent.vue';
  import TimelineComponent from '@/components/timeline/TimelineComponent.vue';
  import AlbumComponent from '@/components/album/AlbumComponent.vue';
  import VideoComponent from '@/components/video/VideoComponent.vue';
  import InvitationComponent from '@/components/invitation/InvitationComponent.vue';
  import CountdownComponent from '@/components/countdown/CountdownComponent.vue';
  import MessageComponent from '@/components/message/MessageComponent.vue';
  import FooterComponent from '@/components/footer/FooterComponent.vue';
  
  export default {
    name: 'HomePage',
    components: {
      PreloaderComponent,
      BannerComponent,
      NameComponent,
      AboutComponent,
      TimelineComponent,
      AlbumComponent,
      VideoComponent,
      InvitationComponent,
      CountdownComponent,
      MessageComponent,
      FooterComponent
    }
  }
  </script>
  
  <style scoped>
  /* Add any scoped styles here if needed */
  </style>